<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2" v-if='bonus'>
                <h3>{{ salaryBonusName }}</h3>
            </b-card-header>

            <salary-bonus-form v-model='bonus'></salary-bonus-form>
        </b-card>

        <control-panel>
            <b-button variant="danger" :to="routerSalaryBonusList()">Вернуться</b-button>
            <b-button variant="warning" @click="deleteSalaryBonus" v-if='!isDisabled'>Удалить</b-button>
            <b-button variant="success" @click="saveSalaryBonus" v-if='this.bonus'>Сохранить</b-button>
        </control-panel>
    </div>
</template>

<script>
    import moment from 'moment';

    import ControlPanel from '../../_common/ControlPanel';
    import ErrorsBagList from '../../_common/ErrorsBagList';

    import ErrorsBag from '../../../utils/errorsBag';
    import { SALARY_ENDPOINT } from '../../../utils/endpoints';

    import SalaryBonusForm from './SalaryBonusForm';

    const DATE_FORMAT = 'YYYY-MM';

    export default {
        name: "SalaryBonusEdit",
        props: [ 'id' ],
        components: { ControlPanel, ErrorsBagList, SalaryBonusForm },
        computed: {
            isDisabled() {
                if ( !this.bonus ) {
                    return true;
                }

                let validFrom = moment( this.bonus.valid_from );

                return moment().startOf( 'day' ).isBefore( validFrom )
            },
            salaryBonusName() {
                return this.bonus.responsibilityType.name + ' для должности "' +
                    this.bonus.workPosition.name + '" активна с ' +
                    moment( this.bonus.valid_from ).format( DATE_FORMAT );
            }
        },
        data() {
            return {
                moment,
                ErrorsBag,

                bonus: null
            };
        },
        methods: {
            routerSalaryBonusList() {
                return { name: 'SalaryBonusList' };
            },

            deleteSalaryBonus() {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить эту настройку?' ) ) {
                    this.$http
                        .delete( SALARY_ENDPOINT + '/bonus/' + this.id )
                        .then(
                            () => {
                                this.$emit( 'delete-salary-bonus', null );
                                this.$router.push( { name: 'SalaryBonusList' } );
                            },
                            response => ErrorsBag.fill( response.body )
                        );
                }
            },
            saveSalaryBonus() {
                ErrorsBag.discard();

                let params = {
                    config: this.bonus.config.filter(el => el.value !== null),
                    valid_from: moment( this.bonus.valid_from ).format( 'YYYY-MM' ),
                    work_position: { id: this.bonus.workPosition ? this.bonus.workPosition.id : null },
                    calculation_type: { id: this.bonus.calculationType ? this.bonus.calculationType.id : null },
                    responsibility_type: { id: this.bonus.responsibilityType ? this.bonus.responsibilityType.id : null },
                    check_type: { id: this.bonus?.salaryBonusCheckType?.id },
                    addition: { id: this.bonus?.salaryBonusAddition?.id},
                };

                this.$http
                    .put( SALARY_ENDPOINT + '/bonus/' + this.id, params )
                    .then(
                        () => this.$router.push( { name: 'SalaryBonusList' } ),
                        response => ErrorsBag.fill( response.body )
                    );
            },

            fetchSalaryBonus() {
                ErrorsBag.discard();

                let params = {
                    with: [ 'config.attribute', 'workPosition', 'calculationType', 'responsibilityType', 'salaryBonusAddition', 'salaryBonusCheckType']
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/bonus/' + this.id, { params } )
                    .then( response => this.bonus = response.data.data, response => ErrorsBag.fill( response.body ) );
            }
        },
        mounted() {
            this.fetchSalaryBonus();
        }
    }
</script>
